html,body {
  margin: 0;
  display: flex;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  flex-grow: 1;
  height: 100%;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {

display: flex;
flex-grow: 1;
}

:root {
	--color-grey-25: #FCFCFD;
	--color-grey-50: #F9FAFB;
	--color-grey-100: #F2F4F7;
	--color-grey-200: #E4E7EC;
	--color-grey-300: #D0D5DD;
	--color-grey-400: #98A2B3;
	--color-grey-500: #667085;
	--color-grey-600: #475467;
	--color-grey-700: #344054;
	--color-grey-800: #1D2939;
	--color-grey-900: #101828;
	--color-red-25: #F8AFB8;
	--color-red-50: #F79CA7;
	--color-red-100: #F58492;
	--color-red-200: #F47383;
	--color-red-300: #F25F70;
	--color-red-400: #F0475B;
	--color-red-500: #EE2F46;
	--color-red-600: #E7132C;
	--color-red-700: #D91229;
	--color-red-800: #BC1024;
	--color-red-900: #960D1D;
	--color-purple-25: #D6BCF5;
	--color-purple-50: #CBAAF3;
	--color-purple-100: #BF98F0;
	--color-purple-200: #B586EE;
	--color-purple-300: #A36CEA;
	--color-purple-400: #9555E7;
	--color-purple-500: #8236E2;
	--color-purple-600: #661CC4;
	--color-purple-700: #5818A9;
	--color-purple-800: #451386;
	--color-purple-900: #320E62;
	--color-orange-25: #F8D6AF;
	--color-orange-50: #F7CC9C;
	--color-orange-100: #F5C084;
	--color-orange-200: #F4B873;
	--color-orange-300: #F2AD5F;
	--color-orange-400: #F0A147;
	--color-orange-500: #EE952F;
	--color-orange-600: #E78413;
	--color-orange-700: #D97C12;
	--color-orange-800: #BC6C10;
	--color-orange-900: #96560D;
	--color-back-office-25: #F5FBFF;
	--color-back-office-50: #F0F9FF;
	--color-back-office-100: #E0F2FE;
	--color-back-office-200: #8CBAD9;
	--color-back-office-300: #73B7E5;
	--color-back-office-400: #50A9E5;
	--color-back-office-500: #3698D9;
	--color-back-office-600: #2C7CB2;
	--color-back-office-700: #266B99;
	--color-back-office-800: #1D5073;
	--color-back-office-900: #163E59;
	--color-blackorwhite-black: #000000;
	--color-blackorwhite-white: #ffffff;
	--color-primary-25 :#DDA5F3;
	--color-primary-50 :#D185EF;
	--color-primary-100 :#C76AEC;
	--color-primary-200 :#BE53E9;
	--color-primary-300: #9847DD;
	--color-primary-400: #AD26E3;
	--color-primary-500: #A01CD4;
	--color-primary-600: #9012BA;
	--color-primary-700: #701395;
	--color-primary-800: #550F70;
	--color-primary-900: #3A0A4D;
	--color-secondary-25 :#A3F5DC;
	--color-secondary-50 :#5EEDC2;
	--color-secondary-100 :#3EEAB6;
	--color-secondary-200 :#19E1A5;
	--color-secondary-300: #17D39B;
	--color-secondary-400: #37BE8C;
	--color-secondary-500: #13A97C;
	--color-secondary-600: #10936C;
	--color-secondary-700: #0D7758;
	--color-secondary-800: #0A5740;
	--color-secondary-900: #063728;
	--color-tertiary-25 :#AAE5F8;
	--color-tertiary-50 :#89DAF5;
	--color-tertiary-100 :#6DD1F3;
	--color-tertiary-200 :#4CC7F0;
	--color-tertiary-300: #2BBDEE;
	--color-tertiary-400: #12AEE2;
	--color-tertiary-500: #1099C6;
	--color-tertiary-600: #0E83AA;
	--color-tertiary-700: #0B6D8D;
	--color-tertiary-800: #0A5E7B;
	--color-tertiary-900: #074155;
	--color-yellow-25: #FBFBA7;
	--color-yellow-50: #FAFA89;
	--color-yellow-100: #F9F976;
	--color-yellow-200: #F8F859;
	--color-yellow-300: #F7F74A;
	--color-yellow-400: #F6F62C;
	--color-yellow-500: #F5F50A;
	--color-yellow-600: #DCDC09;
	--color-yellow-700: #C6C610;
	--color-yellow-800: #A5A50D;
	--color-yellow-900: #8D8D0B;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: white;
    border-radius: 8px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
	position: relative;
	width: 80%;
	
}

.modal-title-container {
    align-items: center;
    background-color: var(--color-back-office-100);
    color: var(--color-grey-800);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 16px;
    border-radius: 8px 8px 0 0;
    width: 80%;
	box-sizing: border-box;
    position: fixed;
    z-index: 10;
    width: 80%;
}

.modal-close{
	display: flex;
	align-items: center;
	height: 48px;
}

.internal-modal-routes-container{
	display: flex;
	flex-direction: row;
	
	gap:24px;
	margin-top: 50px;
	width: fit-content;
}

.modal-change-closing-date-container{
	display: flex;
	flex-direction: column;
	gap:16px;
	width: fit-content;
}

.form-change-route-setter{
	display: flex;
	flex-direction: row;
	gap:16px;
	color: var(--color-grey-700);
}

.modal-change-route-setter{
	display: flex;
	flex-direction: column;
	gap:24px;
	width: fit-content;
	
	
}

.form-change-closing-date-container{
	display: flex;
	flex-direction: column;
	gap:4px
}

.error-change-date-message{
	color: var(--color-red-300);
	gap:12px;
	padding: 0px 12px;
	display: flex;
	flex-direction: row;

}

.checkbox-group-container{
	display: flex;
	flex-direction: column;
	gap:8px
}


.checkbox-group{
	display: flex;
	flex-direction: column;
	gap:4px
}

.checkbox-indiv{
	display: flex;
	flex-direction: row;
	gap:8px
}

.change-date-message{
	color: var(--color-grey-600);
	gap:12px;
	padding: 0px 12px;
	display: flex;
	flex-direction: row;
	
}

.modal-change-closing-date-button{
	align-self: flex-end;
	display: flex;
	flex-direction: row;
	gap:24px

}

.modal-change-closing-date-button-confirm{
	padding: 12px 16px;
	border-radius: 10px;
	color: white;
	background-color: var(--color-back-office-700);
}

.modal-change-closing-date-button-confirm-disabled{
	padding: 12px 16px;
	border-radius: 10px;
	color: white;
	background-color: var(--color-back-office-700);
	opacity: 0.2;
}

.modal-change-closing-date-button-cancel{
	padding: 12px 16px;
	border-radius: 10px;
	color: var(--color-back-office-800);
	border: solid 1px var(--color-grey-300);
}

#demontage-date{
	padding: 12px 8px;
	border : solid 1px var(--color-grey-100);
	border-radius: 10px;
	background-color: var(--color-grey-50);
	width: 300px;
}

.detail-routes-modal-box{
	display: flex;
	flex-direction: column;
	gap:4px;
	padding : 16px;
	border: solid;
	border-width: 1px;
	border-color: var(--color-back-office-800);
	border-radius: 10px;
}

.detail-routes-modal-box-2{
	display: flex;
	flex-direction: column;
	gap:32px;
	padding : 16px;
	border: solid;
	border-width: 1px;
	border-color: var(--color-grey-500);
	border-radius: 10px;
	min-width: 40%
}

@font-face {
  font-family: 'Nunito Sans';
  src: 
  url('../../staticfiles/admin/fonts/NunitoSans-VariableFont_YTLC\,opsz\,wdth\,wght.ttf') format('TrueType');
  font-weight: 400 ;

}


@font-face {
	font-family: 'Nunito Sans';
	src: 
	url('../../staticfiles/admin/fonts/NunitoSans_10pt-Black.ttf') format('TrueType');
	font-weight:  900;
  
}


@font-face {
	font-family: 'Nunito Sans';
	src: 
	url('../../staticfiles/admin/fonts/NunitoSans_10pt-Bold.ttf') format('TrueType');
	font-weight: 700 ;
  
}

@font-face {
	font-family: 'Nunito Sans';
	src: 
	url('../../staticfiles/admin/fonts/NunitoSans_10pt-ExtraBold.ttf') format('TrueType');
	font-weight: 800 ;
  
}

@font-face {
	font-family: 'Nunito Sans';
	src: 
	url('../../staticfiles/admin/fonts/NunitoSans_10pt-Regular.ttf') format('TrueType');
	font-weight: 400 ;
  
}

@font-face {
	font-family: 'Nohemi';
	src: 
	url('../../staticfiles/admin/fonts/Nohemi-Bold.ttf') format('TrueType');
	font-weight: 700 ;
  
}

@font-face {
	font-family: 'Nohemi';
	src: 
	url('../../staticfiles/admin/fonts/Nohemi-Regular.ttf') format('TrueType');
	font-weight: 400 ;
  
}

.box-shadow{ 
	box-shadow:  2px 2px rgba(0, 0, 0, 0.25)
};

.non-clickable {
	pointer-events: none;
	cursor: wait;
}

.no-pointer{
	cursor: default;
}

  /* Headings */

.headings-nohemi-2{
	font-family: 'Nohemi', sans-serif;
  	font-size: 36px;
  	line-height: 44px;
	font-weight: 700;
}

.headings-nohemi-4{
	font-family: 'Nohemi', sans-serif;
	font-weight: 400;
  	font-size: 24px;
  	line-height: 30px;
}

.headings-nohemi-4-bold{
	font-family: 'Nohemi', sans-serif;
	font-weight:700;
  	font-size: 24px;
  	line-height: 30px;
}

h1 {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  font-size: 39.8px;
  line-height: 48px;
}

h2 {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  font-size: 33.2px;
  line-height: 40px;
}

h3 {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  font-size: 27.6px;
  line-height: 32px;
}

h4 {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.light {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 200;
}

.regular {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
}

.bold {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
}

.extrabold {
	font-family: 'Nunito Sans', sans-serif;
	font-weight: 900;
  }

.lower2 {
  font-size: 12px;
  line-height:16px;
}

.lower {
  font-size: 14px;
  line-height:18px;
}

.normal {
  font-size: 16px;
  line-height:20px;
}

.large {
  font-size: 18px;
  line-height: 22px;
}

.large2 {
    font-size: 20px;
    line-height: 24px;
}

.large3{
	font-size: 24px;
	line-height: 28px;
}

.large4{
	font-size: 28px;
	line-height: 32px;
}

.heading{
	font-size: 36px;
	line-height: 48px;
}

.heading1{
	font-size: 48px;
	line-height: 54px;
}


*{
  margin :0;
  padding :0;
  box-sizing: border-box;
}

/* Landing-page */

.Landing-page-background {
	display: flex;
	height: fit-content;
	width: 100%;
	background-color: var(--color-secondary-300);
	align-items: center;
	flex-direction: column;
	gap:32px;
	padding-top: 32px;

}

.Landing-page-header{
	display: flex;
	flex-direction: row; /* Creates three equal-width columns */
	align-items: center;
	justify-content:space-between;
	width: 100%;
	min-width: 1440px;
	
	
}

.logo-gaston-landing-page{
	display: flex;
	justify-self: center;
}

.Landing-page-route-setter-button{
	justify-self: flex-end;
	border-radius: 15px;
	background-color: var(--color-back-office-600);
	align-self: center;

}

.check-challenges-button{
	justify-self: flex-end;
	border-radius: 15px;
	background-color: var(--color-yellow-200);
	align-self: center;

}

.check-challenges-button-text{
	padding-left:24px;
	padding-right: 24px;
	padding-top: 16px;
	padding-bottom: 16px;
	color: var(--color-grey-800);
	font-family: 'Nunito Sans', sans-serif;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	margin-left: 92px;
}


.Landing-page-route-setter-button-text{
	padding-left:24px;
	padding-right: 24px;
	padding-top: 16px;
	padding-bottom: 16px;
	color:white;
	font-family: 'Nunito Sans', sans-serif;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	margin-right: 92px;
}

.Landing-page-presentation-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    margin-left: 92px;
    margin-right: 92px;
    background-color: var(--color-primary-200);
    padding: 32px; /* Make sure to specify units for padding */
    position: relative; /* Needed for absolute positioning */
	border-radius: 20px;
	border-width: 2px;
	border:solid;
	border-color: black;
	box-shadow: 2px 4px 0px 0px #000;
	gap:44px;
	min-width: 1274px;
}

.Landing-page-presentation-description{
	display: flex;
    flex-direction: column;
    align-items: flex-start;
	gap:24px
}

.Landing-page-presentation-description-last-text-box{
	display: flex;
    flex-direction: row;
    align-items: flex-start;
	gap:4px
}

.Landing-page-presentation-description-focus-text{
	background-color: var(--color-grey-800);
	color: white;
	font-family: 'Nunito Sans', sans-serif;
	font-size: 38px;
	font-style: normal;
	font-weight: 800;
	line-height: 60px;
	padding-left: 8px;
	padding-right: 8px;
	display: flex; /* Make the <p> tag a flex container */
	vertical-align: bottom;
}

.Landing-page-presentation-description-standard-text{
	display: flex;
	flex-direction: column;
	color: white;
	font-family: 'Nunito Sans', sans-serif;
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	line-height: 38px;
	padding-left: 24px;
	padding-right: 24px;
	gap:16px
}

.Landing-page-presentation-description-standard-text-last{
	display: flex;
	color: white;
	font-family: 'Nunito Sans', sans-serif;
	font-size: 38px;
	font-style: normal;
	font-weight: 700;
	line-height: 56px;
	gap:4px
	
}

.extended-row-title-container-route-summary-second-part-stats-portion-title{
	color: var(--color-back-office-800);
}

.extended-row-title-container-route-summary-second-part-stats-portion-subtitle{
	color: var(--color-grey-500);
}


.Landing-page-presentation-container img {
    width: 476px; /* Width as specified */
    object-fit: contain;
}

.Landing-page-gym-finder-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background-color: var(--color-grey-200);
    padding: 24px; /* Make sure to specify units for padding */
    position: relative; /* Needed for absolute positioning */
	border-radius: 20px;
	border-width: 2px;
	border:solid;
	border-color: black;
	box-shadow: 2px 4px 0px 0px #000;
	gap:24px;
	min-width: 1274px;
	width: calc(100%-184px);
}

.Landing-page-gym-finder-formik-container{
	display: flex;
    flex-direction: column;
    align-items: flex-start;
	
}

.Landing-page-gym-finder-header-title{
	color: var(--color-grey-800);
	font-family: 'Nunito Sans', sans-serif;
	font-size: 28px;
	font-style: normal;
	font-weight: 900;
	line-height: 34px; 
	
}

.Landing-page-gym-finder-header-subtitle{
	color: var(--color-grey-800);
	font-family: 'Nunito Sans', sans-serif;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; 
}

.Landing-page-gym-finder-header-description{
	color: var(--color-grey-800);
	font-family: 'Nunito Sans', sans-serif;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
}

.Landing-page-gym-finder-header-description-error{
	color: var(--color-red-600);
	font-family: 'Nunito Sans', sans-serif;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
}

.Landing-page-gym-finder-formik-input{
	display: flex;
    flex-direction: row;
    align-items: flex-start;
	gap: 24px;
}

.Landing-page-gym-finder-formik-error{
	margin-left: 16px;
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
	color: var(--color-red-600);
}

.Landing-page-gym-finder-formik-input-inner-container{
	display: flex;
    flex-direction: column;
    align-items: flex-start;
	gap: 12;
}

.autocomplete-container {
	position: relative;
	width: 281px; /* Adjust as necessary */
}
.input-select-gym::placeholder {
	color: var(--color-grey-600); /* Light gray placeholder text */
}
.input-select-gym{
	padding:16px;
	border-radius: 10px;
	border: 1px solid var(--color-grey-600);
	background-color: white;
	min-width: 281px;
	max-width: 281px;
	font-family: 'Nunito Sans', sans-serif;
	color: var(--color-grey-800);
	font-size: 20px;
	line-height: 24px;
	font-style: normal;
	font-weight: 300;
}

.autocomplete-dropdown {
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	z-index: 1000;
	padding: 0;
	margin: 0;
	background-color: #ffffff;
	border: 1px solid #ddd;
	border-top: none;
	border-radius: 0 0 4px 4px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	max-height: 200px; /* Adjust as necessary */
	overflow-y: auto; /* Scrollable if too many items */
}

.autocomplete-dropdown li {
list-style: none;
padding: 10px;
cursor: pointer;
transition: background-color 0.2s ease;
}

.autocomplete-dropdown li:hover {
background-color: #f8f8f8;
}

/* Optional: for no border between input and dropdown */
.autocomplete-input:focus {
outline: none;
border-color: #aaa;
}

.autocomplete-dropdown li:not(:last-child) {
border-bottom: 1px solid #eee;
}

.Landing-page-gym-finder-button{
	border-radius: 25px;
	background-color: var(--color-primary-300);
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 16px;
	padding-bottom:16px ;
	margin-top: 16px;
}

.Landing-page-gym-finder-button-text{
	color: white;
	font-family: "Nunito Sans", sans-serif;
	font-size: 24px;
	font-style: normal;
	font-weight: 800;
	line-height: 24px; /* 100% */
}

.Landing-page-gym-finder-header-condensed{
	display: flex;
    flex-direction: row;
	gap:4px
}

.Landing-page-gym-finder-pro{
	display: flex;
    flex-direction: row;
    align-items: flex-start;
	gap: 16px;
}

.Landing-page-gym-finder-header-professional-button{
	border-radius: 10px;
	border: 2px solid var(--color-primary-400);
	background: var(--color-back-office-25);
	padding: 16px 24px;
	align-items: center;
	justify-content: center;
	text-wrap: nowrap;
	
}

.Landing-page-gym-finder-header-professional-button-text{
	font-family: "Nunito Sans", sans-serif;
	color: var(--color-primary-500);
	font-size: 24px;
	font-style: normal;
	font-weight: 800;
	line-height: 28px;
	text-align: center;
	
}

.landing-page-footer{
	display: grid;
	grid-template-columns: repeat(2, 1fr); /* Creates three equal-width columns */
	align-items: center;
	width: 100%;
	min-width: 1440px;	
}

.landing-page-footer-left-side{
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 182px;
	padding: 16px 32px;
	gap:24px;
	background-color: var(--color-tertiary-400);
}

.landing-page-footer-right-side{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	height: 182px;
	padding: 16px 32px;
	gap:32px;
	background-color: var(--color-yellow-400);
}

.landing-page-footer-title{
	color: var(--color-grey-800);
	font-family:"Nunito Sans", sans-serif;
	align-self: flex-start;
	font-size: 36px;
	font-style: normal;
	font-weight: 900;
	line-height: 38px;
}

.landing-page-footer-left-side-button-container{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap:16px;
	text-decoration: none;
	
}

.landing-page-footer-partner-button-text{
	color: var(--color-grey-800);
	font-family:"Nunito Sans", sans-serif;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px
}

.landing-page-footer-right-side-socials-container{
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap:64px;
	padding-left: 24px;
}

.app-demo-container-mobile{
	display: none;
}



/* Login-route-setter */
.login_outercontainer {
	display: flex;
	align-items: center;
	padding-top: 16px;
	justify-content: flex-start;
	height: 100%;
	width: 100%;
	background-color:var(--color-back-office-100) ;
	flex-direction: column;
	gap:44px;
	padding-bottom: 16px;
	
}

.party_outercontainer {
	display: flex;
	align-items: center;
	padding-top: 16px;
	justify-content: flex-start;
	height: 100vh;
	width: 100%;
	background-color:var(--color-secondary-200) ;
	flex-direction: column;
	
}

.party_outercontainer_bis {
	display: flex;
	align-items: center;
	padding-top: 16px;
	justify-content: flex-start;
	height: 100%;
	width: 100%;
	background-color:var(--color-secondary-400) ;
	flex-direction: column;
	flex: 1;
	
}

.party_header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding-top: 28px;
	padding-bottom: 28px;
	padding-left: 80px;
	padding-right: 80px;
}

.party_header_bis {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding-top: 28px;
	padding-bottom: 28px;
	padding-left: 80px;
	padding-right: 80px;
	background-color: var(--color-secondary-400);
}

.title_party_header{
	color: var(--color-grey-800);
	text-align: center;
}

.title_party_header_bis{
	color: white;
	text-align: center;
}

.select-gym-button{
	cursor: pointer;
	border-radius: 10px;
	padding:16px 24px;
	background-color: var(--color-back-office-700);
	color: var(--color-grey-50);
	width: 60%;
	display: flex;
    justify-content: center;
}

.select-gym-button-inactive{
	cursor: pointer;
	border-radius: 10px;
	padding:16px 24px;
	color: var(--color-back-office-800);
	width: 60%;
	
	border : solid 1px var(--color-back-office-800);
	
	display: flex;
    justify-content: center;
}

.gym-list{
	display: flex;
    height: 70vh;
    width: 70vh;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 32px 0px;
    gap: 16px;
}

.party_subHeader{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding-top: 20px;
	padding-bottom: 20px;
	gap:160px;
	background-color: var(--color-primary-300);
	
}

.party_subHeader_component{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap:12px;	

}

.party_subHeader_component_2{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap:12px;	
	width: 33%;
}
.party_ranking_tabs_section{
	align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: center;
	gap: 40px;
	flex-wrap: wrap;
	width: 100%;
	
}

.rank_icon_written{
	border: solid 1px var(--color-grey-400);
	color: var(--color-grey-400);
	background-color: var(--color-grey-100);
	border-radius: 8px;
	padding: 4px;
	width: fit-content;
}

.party_ranking_section{
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;

	background-color: var(--color-primary-50);
	width: 100%;
	padding: 32px 15%;
	
}

.party_ranking_section_bis{
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: row;
	gap :16px;
	background-color: var(--color-primary-300);
	width: 100%;
	padding: 32px 12px;
	flex-wrap: wrap;
	flex-grow: 1;
	
	
}

.party_ranking_tabs_section_container{
	padding: 24px;
	align-items: center;
	display: flex;
	flex-direction: column;
	gap:56px;
	border : solid 1px black;
	background-color: var(--color-grey-50);
	border-radius: 12px;
	border-width: 1px 3px 4px 1px;
    box-shadow: 1px 2px 2px 0 #00000040;
	
}

.party_ranking_tabs_section_container_bis{
	padding: 24px;
	align-items: center;
	display: flex;
	flex-direction: column;
	gap:56px;
	border : solid 1px black;
	background-color: var(--color-grey-50);
	border-width: 1px 3px 4px 1px;
    box-shadow: 1px 2px 2px 0 #00000040;
	max-width: 650px;
	width: 100%;
	
}

.party_ranking_tabs_section_upper_section{
	display: flex;
	flex-direction: column;
	gap:16px;
	align-items: center;
}

.party_ranking_tabs_section_upper_section_bis{
	display: flex;
	flex-direction: column;
	gap:8px;
	align-items: center;
}

.party_ranking_tabs_text{
	display: flex;
	flex-direction: column;
	align-items: center;
	color: var(--color-grey-800);
	gap:12px;
	text-align: center;
}

.party_ranking_tabs_subtext{
	display: flex;
	flex-direction: column;
	align-items: center;
	color: var(--color-grey-800);
	gap:8px
}

.ranking_table_container{
	display: flex;
	width: 400px;
	background-color: var(--color-grey-50);
	border-radius: 12px;
	gap:16px;
	padding-bottom: 24px;
	align-items: center;
	justify-content: flex-start;
	border-bottom-width: 4px;
	border-right-width: 3px;
	flex-direction: column;
}

.ranking_table_header_section{
	padding-left: 16px;
	padding-right: 16px;
	gap:16px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
}

.ranking_table_one_challenge_header_section{
	padding: 8px 16px;
	color: var(--color-primary-500);
	border: 2px solid var(--color-primary-500);
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.ranking_section_header{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
    border-bottom: solid;
    border-bottom-width: 1px;
    padding: 8px;
	
	
}

.ranking_section{
	display: flex;
	flex-direction: column;
	gap:2px;
	width:80%
	
}

.table_line__rank{
	color: black;
	width: 30%;
}

.table_line__name{
	color: black;
	width: 66%;
    text-align: left;
}

.table_line__points{
	color: black;
	width: 40%;
    text-align: end;
}

.ranking_line_container{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
    padding: 8px;
}

.login_container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: white;
	gap:32px;
	padding: 24px;
	
	border: solid;
	border-width: 1px;
	border-radius: 20px;
	border-color: var(--color-grey-300);
	box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.25);
	width: 480px;
	
}

.login_button{
	margin-top: 16px;
	padding-left: 24px;
	padding-top: 20px;
	padding-right: 24px;
	padding-bottom: 20px;
	background-color: var(--color-back-office-700);
	border-radius: 10px;
	color: white;
	font-size: 24px;
	line-height: 28px;
	font-weight: 700;
	align-self: center;
	margin-left: 10%;
    margin-right: 10%;
	border: none;
	cursor: pointer;
}

.login_button_disabled{
	margin-top: 16px;
	padding-left: 24px;
	padding-top: 20px;
	padding-right: 24px;
	padding-bottom: 20px;
	background-color: var(--color-back-office-600);
	border-radius: 10px;
	color: white;
	font-size: 24px;
	line-height: 28px;
	font-weight: 700;
	align-self: center;
	margin-left: 10%;
    margin-right: 10%;
	border: none;
	cursor: default;
}

/* Left-sidebar */

.bo-left-sidebar {
	height: 100%;
    display: flex;
    width: 208px;
	padding: 32px 24px 0px 24px;
    flex-direction: column;
    align-items: flex-start;
	justify-content: flex-start;
    flex-shrink: 0;
	color:var(--color-grey-600);
	gap:48px
}

.bo-left-sidebar__logo-layout{
	align-self: center;
}

.bo-left-sidebar__menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
	max-width:100%
 
	
}

.bo-left-sidebar__submenu {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 32px;
	align-self: stretch;
}

.submenu__options {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
	align-self: stretch;
}

.switch-page-button{
	padding: 0 8px 0 8px;
	border-left: solid;
	border-left-width: 2px;
	border-color: var(--color-grey-700);
	gap: 8px;
	color: var(--color-grey-700);
	display: flex;
}

.settings-page-button{
	padding: 0 8px 0 8px;
	gap: 8px;
	color: var(--color-grey-700);
	display: flex;
}

.switch-page-button-active{
	padding: 4px 8px 4px 8px;
	background-color: var(--color-back-office-300);
	border-radius: 6px;
	gap: 8px;
	color: var(--color-back-office-800);
	display: flex;
	width: 100%;
}

.submenu__element {
	display: flex;
	padding: 0px 8px;
	align-items: center;
	gap: 8px;
}

.submenu__gyms {
	display: flex;
	padding: 0px 8px;
	align-items: flex-start;
	gap: 8px;
}

.navbar-line-separator{
	border-color: var(--color-grey-700);
	width: 104px;
	border-bottom : solid;
	border-bottom-width: 0.5px;
}

.gyms__door {
	display: flex;
	padding-top: 4px;
	align-items: center;
	gap: 8px;
}


.gyms__my-gyms {
	display: flex;
	flex-direction: column;
	align-self: stretch;
	align-items: left;
	gap: 10px;
}

.gyms__my-gyms-single-container{
	background-color: var(--color-back-office-500);
	border-radius: 10px;
	padding: 8px 12px 8px 12px;
	color:  white;

}

.gyms__my-gyms-single-container-change{
	background-color: var(--color-back-office-800);
	border-radius: 10px;
	padding: 8px 12px 8px 12px;
	color:  var(--color-grey-100);
	gap: 12px;
    display: flex;
	align-items: center;
}

.submenu__setter-mode {
	display: flex;
	width: 216px;
	padding: 12px 8px;
	align-items: center;
	gap: 8px;
	border-radius: 10px;
	background: var(--color-back-office-600);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.bo-left-sidebar__profile{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
}

.bo-opacity{
	opacity: 0.4;
	cursor: wait;
}

.selection-active{
	cursor:pointer
}

.difficulty-summary-container{
	display: flex;
	align-self: center;
	flex-direction: column;
	justify-content: flex-start;
	background-color: var(--color-back-office-50);
	height: 71%;
	border-radius: 10px;
	padding: 24px 0px 24px 24px;
	width: 100%;
	gap: 14px
}

.difficulty-summary-header-container{
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
}

.difficulty-summary-header{
	display: flex;
	flex-direction: row;
	align-items: center;
	color: var(--color-back-office-800);
	gap:8px;
	width: 100%;
	min-width: fit-content;
}



.difficulty-summary-content{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap:14px;
	color: var(--color-grey-700);
	width: 100%;
	overflow-y: auto;
}

.difficulty-svg{
	background-color: white;
	border: solid;
	border-width: 1px;
}

.difficulty-summary-item-sub-level-container{
width: 80px;
	
}

.difficulty-summary-item-container{
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	color: var(--color-grey-700);
	width: 100%;
	padding-right: 24px;
}

.difficulty-summary-item-main-container{
	display: flex;
	flex-direction: row;
	gap:8px;
	width: 56px;
	justify-content: space-between;
	
}

.difficulty-summary-item-sub-level{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap:4px
}


.bo-left-sidebar__Profile-pictures {
	display: flex;
	justify-content: center;
	align-items: center;
}

.bo-left-sidebar__Profile-pictures img {
	width: 32px;
	height: 32px;
	border-radius: 100%;
}

.bo-left-sidebar__profile-info {
	display: flex;
	align-items: center;
	gap: 8px;	
}

.bo-left-sidebar__profile-settings{
	display: flex;
	align-items: flex-start;
	gap: 8px;	
	flex-direction: column;
}

.bo-left-sidebar__name {
	display: flex;
	align-items: center;
	gap: 8px;
	color: var(--color-grey-700);	
	
}

.chevron {
	position: relative;
	top: 2px;
}

.bo-left-sidebar__switch {
	display: flex;
	align-items: center;
	gap: 8px;	
}

.switch{
	text-decoration: none;
	color : var(--color-back-office-100);
}

.bo-left-sidebar__disconnect {
display: flex;
align-items: center;
gap: 8px;
}

.outerContainer {
	background-color: var(--color-back-office-100);
	width: 100%;
	height: 100%;
	display: flex;
	overflow-y: hidden;
	flex-direction: row;

}

.bo-main-content {

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 32px;
	height: 100vh;
	background: var(--color-back-office-100);
	padding-bottom: 16px;
	width: 100%;
	color: var(--color-back-office-800);
	overflow-y: auto;
}

.bo-main-content__title {
	display: flex;
	align-items: center;
	gap: 8px;
	color: var(--color-back-office-800);
	margin-bottom: -12px;
}

.filter-toggle{
	display: flex;
	flex-direction: row;
	gap:8px
	
}

.box-stat-container{
	display: flex;
	flex-direction: column;
	padding: 12px 16px 8px 16px;
	background-color: var(--color-back-office-600);
	border-radius: 10px;
	color: white;
	border : solid 1px var(--color-back-office-600)
}

.box-stat-container-incoming{
	display: flex;
	flex-direction: column;
	padding: 12px 16px 8px 16px;
	background-color: var(--color-grey-400);
	border-radius: 10px;
	color: white;
	border : solid 1px var(--color-grey-600)
}

.box-stat-container-hovered{
	display: flex;
	flex-direction: column;
	padding: 12px 16px 8px 16px;
	background-color: var(--color-back-office-25);
	border-radius: 10px;
	color: var(--color-back-office-800);
	border : solid 1px var(--color-back-office-500)
}

.box-date-container{
	display: flex;
	flex-direction: column;
	padding: 12px 12px 8px 12px;
	background-color: var(--color-back-office-25);
	border : solid 1px var(--color-back-office-500);
	border-radius: 10px;
	color: var(--color-back-office-800)
}

.change-password-admin{
	display: flex;
	border-radius: 10px;
	padding: 16px 24px;
	border : solid 1px var(--color-back-office-800);
	color: var(--color-back-office-800);
}

.box-stat-actual-stat-container{
	display: flex;
	flex-direction: row;

}

.box-stat-actual-stat-sector-container{
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	gap:4px

}

.box-stat-actual-date-sector-container{
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap:8px;
	justify-content: space-between;

}

.stat-icon {
	display: flex;
	align-self: flex-start;
}
.stat-is-up{
	margin-left: 4px;
	display: flex;
	align-self: flex-start;
	color: var(--color-secondary-100);
}

.stat-is-equal{
	margin-left: 4px;
	display: flex;
	align-self: flex-start;
	color: var(--color-grey-100);
}

.stat-is-down{
	margin-left: 4px;
	display: flex;
	align-self: flex-start;
	color: orange;
}

.stats-outer-container-home{
	display: flex;
	flex-direction: row;
	gap:24px
}


.pannels-filter__pannel-selection {
	display: flex;
	flex-direction: row;
	width: 1051px;
	padding: 16px;
	align-items: flex-start;
	gap: 24px;
	background-color: var(--color-grey-25);
	border-radius: 10px;
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.pannel-selection__library {
	color: var(--color-back-office-500);	
}

.pannel-selection_route-setting{
	color: var(--color-back-office-800);
}

a.setting_link {
	color: var(--color-blackorwhite-white);
	text-decoration: none;
}

.pannels-filter__filters {
	display: flex;
	padding: 16px 12px;
	align-items: flex-start;
	border-radius: 8px;
	background-color: white;
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
	min-width: 1172px;
	width: 100%;
	flex-direction: column;	
	gap:16px
}

.pannels-filter__filters-with-2-1 {
	display: flex;
	padding: 12px 16px;
	align-items: flex-start;
	border-radius: 8px;
	background-color: white;
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

	width: 70%;
	flex-direction: column;	
	gap:16px
}

.pannels-filter__filters-with-2-2 {
	display: flex;
	padding: 12px ;
	align-items: flex-start;
	border-radius: 8px;
	background-color: white;
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
	color: var(--color-grey-700);
	width: 30%;
	flex-direction: column;	
	gap:12px;
	height: 100%
}

.header-tips{
	display: flex;
	flex-direction: row;
	gap:16px;
	color:var(--color-back-office-800)
}



.pannels-filter__filters-with-2 {
	display: flex;
	align-items: flex-start;
	min-width: 1172px;
	width: 100%;
	flex-direction: row;	
	gap:20px

}

.filter-card{
	padding: 4px 8px;
	border: 1px solid var(--color-back-office-500);
	color: var(--color-back-office-500);
	background-color: var(--color-back-office-100);
	border-radius: 4px;
}

.filter-header{
	display: flex;
	flex-direction: row;
	gap:24px;
}

.filter-selection-basic {
	display: flex;
	width: 166px;
	padding: 8px 16px 16px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;	
	height: 240px;
	border-radius: 6px;
	color: var(--color-back-office-800);
	border : solid;
	border-width: 1px;
	border-color: var(--color-grey-300);
}

.filter-selection-basic__difficulty {
	display: flex;
	width: 224px;
	height: 138px;
	padding: 8px 16px 16px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 6px;	
	border-radius: 6px;
	color: var(--color-back-office-800);
	border : solid;
	border-width: 1px;
	border-color: var(--color-grey-300);
	
}

.filter-selection-basic__gym {
	display: flex;
	width: 196px;
	height: 138px ;
	padding: 8px 16px 16px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;	
	border-radius: 6px;
	color: var(--color-back-office-800);
	border : solid;
	border-width: 1px;
	border-color: var(--color-grey-300);
}

.filter-selection-basic__sector {
	display: flex;
	width: 100%;
	height: 240px;
	padding: 8px 16px 16px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;	
	border-radius: 6px;
	color: var(--color-back-office-800);
	border : solid;
	border-width: 1px;
	border-color: var(--color-grey-300);
}

.filter-selection-basic-2 {
	display: flex;
	padding: 16px;
	padding-bottom: 14px;
	flex-direction: row;
	align-items: flex-start;
	gap: 24px;	
	border-radius: 6px;
	color: var(--color-back-office-800);
	border : solid;
	border-width: 1px;
	border-color: var(--color-grey-300);
}


.filter-selection-basic-for-2-filters {
	display: flex;
	flex-direction: column;
	gap: 16px;
	
	
}
.title-checkmark{
	display: flex;
	flex-direction: column;
	gap:4px;
}

.filter-selection__all-toggles {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;	
	height: 90%;
}

.filter-selection__all-toggles__sector {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;	
	height: 90%;
	width: 100%;
}

.all-filter {
	display: flex;
	padding: 2px 0px;
	align-items: center;
	gap: 8px;
	color: var(--color-grey-600);
	
}

.hidden {
	display: none;
  }
  
.filter-selection__all-colors{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	height: 100%;
	width: 100%;
	gap: 4px;
	overflow-y: auto;
	padding-bottom: 16px;
		
}

.boulders-color {
	display: flex;
	align-items: flex-start;
	align-content: flex-start;
	gap: 8px;
	height: 100%;
	
	flex-wrap: wrap;  
	
    

}

.boulder-color__filter {
	height: 20px;
	Width: 20px;
	
}

.boulder-difficulty__filter {
	display: flex;
	height: 24px;
	Width: 24px;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	
}


/*.boulder-color__filter.clicked {
	box-shadow: 0px 2px 2px 0px rgba(71, 84, 103, 0.8);
}
*/

.clicked2 {
	border: solid;
	border-radius: 3px;
	border-color: var(--color-grey-500);
	border-width: 2px;
	padding: 1px;
}

.clicked3 {
	border: solid;
	border-color: var(--color-grey-500);
	border-width: 2px;
	padding: 1px;
}



.text-hidden {
	visibility: hidden;
	
}
.filter-selection-grade__min-max {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	align-self: stretch;	
}

.min-max__min-max {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;	
}

.min-max__border:focus {
    outline-color: var(--color-back-office-800);
	outline-style: solid;
    outline-width: 2px; /* Adjust as needed */
	border: none;
}

.second-sector-summary-part{
	display: flex;
	flex-direction: column;
	gap:24px
}

.min-max__border {
	display: flex;
	width: 40px;
	padding: 8px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 10px;
	border: 1px solid var(--color-grey-700);
}

.filter-selection-extended {
	display: flex;
	padding: 8px 16px 16px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	border-radius: 6px;
	color: var(--color-back-office-800);
	height: 240px;
	border: 1px solid var(--color-grey-300);
}

.filter-selection-extended__all-columns {
	display: flex;
	align-items: flex-start;
	gap: 24px;
	height: 81%;
}

.filter-selection-extended__column {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	height: 100%;	
}

.vertical-toggles {
	display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;	
    max-height: 100%;
    overflow-y: auto;  /* Enables vertical scrolling */
    flex-wrap: nowrap;
}

.vertical-toggles__sector {
	display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 4px;	
    max-height: 100%;
	width: 100%;
    overflow-y: auto;  /* Enables vertical scrolling */
    flex-wrap: wrap;
}

.toggle-filter {
	display: flex;
	padding: 2px 8px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 15px;
	
	
}

.level-inner-container{
	display: flex;
	flex-direction: column;
	gap:4px;
	align-items: center;
	justify-content: center;
}

.levels-display{
	display: flex;
	flex-direction: row;
}

.level-1{
	width: 16px;
	height: 16px;
	border-radius: 3px 0px 0px 3px;
	margin-right: 2px;
}

.blue-level-1{
	background-color: var(--color-back-office-200);
}

.level-2{
	width: 16px;
	height: 16px;

}

.blue-level-2{
	background-color: var(--color-back-office-300);
}

.level-3{
	width: 16px;
	height: 16px;
	border-radius: 0px 3px 3px 0px;
	margin-left: 2px;
}

.blue-level-3{
	background-color: var(--color-back-office-600);
}

.grey-level{
	background-color: var(--color-grey-300);
}

.grey {
	background-color: var(--color-grey-300);
	color: var(--color-grey-500);
}

.blue {
	background-color: var(--color-back-office-500);
	color: var(--color-grey-100);
}

.main-content__boulders-library {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 10px;
	background: var(--color-grey-25);
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);	
	min-width: 1131px;
	margin-top: 16px;
	width: 100%;
	
}

.sector-header-selected-sector-name{
	min-width: 22%;
	width: 22%;
}

.parameter-row-name{
	display: flex;
	min-width: 10%;
	width: 10%;
	align-items: center;
}

.parameter-row-role{
	display: flex;
	min-width: 10%;
	width: 10%;
	align-items: center;
	flex-wrap: wrap;
}

.add-route-setter-button{
	border-radius: 10px;
	background-color: var(--color-back-office-700);
	color : white;
	display: flex;
	flex-direction: row;
	padding: 12px 16px;
	gap :12px
	
}

.parameter-row-gyms{
	min-width: 50%;
	width: 50%;
	display: flex;
	flex-direction: column;
	gap:8px;
	align-items: flex-start;
	justify-content: flex-start;
}

.parameter-row-modify{
	display: flex;
	min-width: 20%;
	width: 20%;
	align-items: center;
	justify-content: center;
}

.parameter-header-name{
	min-width: 10%;
	width: 10%;
}

.parameter-header-role{
	min-width: 10%;
	width: 10%;
}


.parameter-header-gyms{
	min-width: 50%;
	width: 50%;
}

.parameter-header-modify{
	min-width: 20%;
	width: 20%;
}

.parameter-modify{
	display: flex;
	flex-direction: row;
	gap:12px;
	padding: 12px 16px;
	color: var(--color-back-office-800);
	border : solid 1px var(--color-back-office-800);
	border-radius: 10px;
	width: fit-content;
}

.parameters-gyms-list{
	display: flex;
	flex-direction: row;
	gap:16px;
	flex-wrap: wrap;
	width: 100%;
}


.sector-header-selected-sector-difficulty{
	min-width: 5.4%;
	width: 5.4%
}

.sector-header-selected-sector-level{
	min-width: 7.3%;
	width: 7.3%
}

.sector-header-selected-sector-tags{
	min-width: 18.4%;
	width: 18.4%
}

.sector-header-selected-sector-opening{
	min-width: 10.4%;
	width: 10.4%
}

.sector-header-selected-sector-closing{
	min-width: 10.4%;
	width: 10.4%
}

.sector-header-selected-sector-modify{
	min-width: 4%;
	width: 4%
}

.sector-header-name{
	min-width: 29%;
	width: 29%;
}

.sector-header-count{
	min-width: 6.4%;
	width: 6.4%
}

.sector-header-opening{
	min-width: 13.3%;
	width: 13.3%
}

.sector-header-closing{
	min-width: 15.4%;
	width: 15.4%
}

.sector-header-modify{
	min-width: 4%;
	width: 4%
}

.sectors-tab{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 10px;
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);	
	min-width: 62%;
	width: 62%;
	height: fit-content;
}

.parameters-tab{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 10px;
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);	
	min-width: 100%;
	width: 100%;
	height: fit-content;
}

.sectors-selected-sector-tab{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 10px;
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);	
	min-width: 100%;
	width: 100%;
	height: fit-content;
}

.sectors-summary-display{
	display: flex;
	flex-direction: row;
	gap:24px;
	justify-content: flex-start;
	width: 100%;
	align-items: fle;
}

.sector-warning-container{
	display: flex;
	flex-direction: row;
	gap:24px;
}

.empty-sector-container{
	padding: 12px;
	gap: 8px;
	border-radius: 8px;
	border : solid 1px var(--color-orange-500);
	background-color: var(--color-orange-25);
	height: fit-content;
}

.upcoming-sector-container{
	padding: 12px;
	gap: 8px;
	border-radius: 8px;
	border : solid 1px var(--color-red-500);
	background-color: var(--color-red-25);
}

.upcoming-sector-container-header{
	color: var(--color-red-500);
}

.latest-sector-container{
	padding: 12px;
	gap: 8px;
	border-radius: 8px;
	border : solid 1px var(--color-secondary-700);
	background-color: var(--color-secondary-25);
}

.difficulty-headers-visual-container{
	display: flex;
	flex-direction: column;
	gap: 32px;
	width: 100%;
}

.latest-sector-container-header{
	color : solid 1px var(--color-secondary-700);
}

.empty-sector-container-header{
	color: var(--color-orange-500);
}

.empty-sector-container-sector-name{
	color : var(--color-grey-700)

}

.empty-sector-container-sector-dismantled-date{
	color: var(--color-grey-700);
}

.main-content__boulders-library-filters-closed{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 10px;
	background: var(--color-grey-25);
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);	
	min-width: 1131px;
	margin-top: 16px;
	width: 100%;
}

.boulders-library__table-header {
	display: flex;
	min-height: 42px;
	padding: 0px 24px 0px 24px;
	align-items: center;
	gap: 16px;
	border-radius: 10px 10px 0px 0px;
	border: 1px solid var(--color-grey-300);
	color: var(--color-grey-800);
	background-color: var(--color-back-office-100);
	width: 100%;
	justify-content: space-between;

}

.sectors-tab-header{
	display: flex;
	min-height: 42px;
	padding: 12px 0px 12px 12px;
	align-items: center;
	border-radius: 10px 10px 0px 0px;
	border: 1px solid var(--color-grey-2s00);
	color: var(--color-back-office-900);
	background-color: var(--color-back-office-100);
	width: 100%;
	justify-content: space-between;
	gap:12px
}

.sectors-tab-rows{
	display: flex;
	min-height: 42px;
	padding: 8px 0px 8px 12px;
	flex-direction: column;
	align-items: center;
	border-radius: 0px 0px 10px 10px;
	border: 1px solid var(--color-grey-2s00);
	color: var(--color-grey-800);
	width: 100%;
	justify-content: space-between;
	gap:8px
}

.sector-summary-row{
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	padding: 4px 0px;
}

.sector-details-row{
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	padding: 4px 0px;
}

.sector-details-row-name{
	width: 22%;
	min-width: 22%
}

.sector-details-row-difficulty{
	width: 5.4%;
	min-width: 5.4%
}

.sector-details-row-level{
	width: 7.3%;
	min-width: 7.3%;
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
}

.sector-details-row-tags{
	width: 18.4%;
	min-width: 18.4%
}

.sector-details-row-opening{
	width: 10.4%;
	min-width: 10.4%
}

.sector-details-row-closing{
	width: 10.4%;
	min-width: 10.4%
}

.sector-details-row-modify{
	width: 4%;
	min-width: 4%;
}

.sector-summary-row-name{
	width: 29%;
	min-width: 29%;
}

.sector-summary-row-count{
	min-width: 6.4%;
	width: 6.4%
}

.sector-summary-row-opening{
	width: 13.3%;
	min-width: 13.3%;
}

.sector-summary-row-closing{
	min-width: 15.4%;
	width: 15.4%
}

.sector-summary-row-modify{
	width: 4%;
	min-width: 4%;
}
.table-header__gyms {
	width: 114px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap:4px;
	
}

.table-header__sectors {
	min-width: 136px;
	width: 12%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap:4px;
	
}

.sectors-presentation-visual-display-sectors-picture img{
	width: 268px;
	height: 200px;
	border-radius: 5px;
}

.table-header__boulders {
	min-width: 226px;
	width: 20%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap:4px;
	
}

.sectors-presentation-container{
	display: flex;
	flex-direction: column;
	padding: 16px;
	gap : 16px;
	border-radius: 8px;
	background-color: white;
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
	max-width: 55%;
	justify-content: space-between;

}

.sectors-presentation-visual-display{
	display: flex;
	flex-direction: column;
	gap: 28px;
	width: 100%;

}

.sectors-presentation-visual-display-buttons-list{
	display: flex;
	flex-direction: row;
	gap : 8px;
	align-items: flex-start;
	flex-wrap: wrap;
}

.table-header__boulders2 {
	min-width: 226px;
	width: 20%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap:4px;

}

.circuit-level-displayer{
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
}


.sectors-presentation-visual-display-container{
	display: flex;
	width: 100%;
	flex-direction: row;
	gap : 32px;
	position: relative;
}



.sectors-presentation-visual-display-sectors-svgs-container{
	display: flex;
	width: 100%;
	flex-direction: column;
	gap : 18px;
	position: relative;
	align-items: center;

}

.sectors-presentation-visual-display-sectors-picture{
	width: 268px;
	height: 200px;
	border-radius: 5px;
}

.sectors-presentation-visual-display-sectors-svgs-lines{
	display: flex;
	width: 100%;
	position: relative;
	width: 300px;
    height: 150px
}



.separator-line-sectors-presentation{
	display: flex;
	width: 100%;
	border-bottom: solid 1px var(--color-grey-300);
}

.selected-sector-button{
	padding: 8px;
	border : solid 1px var(--color-back-office-500);
	color : var(--color-back-office-500);
	border-radius: 4px;
	background-color: var(--color-back-office-100);

	align-items: center;
}

.select-sector-button{
	padding: 8px;
	border : solid 1px var(--color-grey-100);
	color : var(--color-grey-500);
	border-radius: 4px;
	background-color: var(--color-grey-50);
    display: flex;
    align-items: center;
}

.newest-sector-button{
	padding: 8px;
	border : solid 1px var(--color-secondary-600);
	color : var(--color-secondary-600);
	border-radius: 4px;
	background-color: var(--color-secondary-25);
    display: flex;
    align-items: center;
}

.oldest-sector-button{
	padding: 8px;
	border : solid 1px var(--color-red-500);
	color : var(--color-red-500);
	border-radius: 4px;
	background-color: var(--color-red-25);
    display: flex;
    align-items: center;
}

.table-header__colors {
	min-width: 72px;
	width: 6.4%;
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	align-items: center;
	gap:4px;
	
}
.table-header__colors1 {
	min-width: 72px;
	width: 6.4%;
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	align-items: center;
	gap:4px;

}

.table-header__colors2{
	min-width: 72px;
	width: 6.4%;
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	align-items: center;
	gap:4px;

}

.table-header__difficulty {
	width: 6.4%;
	min-width: 72px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap:4px;
	
}

.table-header__difficulty2 {
	width: 6.4%;
	min-width: 72px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap:4px;
	
}

.table-header__setting {
	min-width: 116px;
	width: 10.3%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap:4px;
	
}

.table-header__tags {
	min-width: 154px;
	width: 13.6%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap:4px;
	
}

.table-header__tags2 {
	min-width: 154px;
	width: 13.6%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap:4px;
	
}

.table-header__status-info {
	min-width: 106px;
	width: 9.4%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap:4px;
	
	justify-content: space-between;
}

.table-header__empty-picture{
	display: flex;
	flex-direction: row;
	align-items: center;
	gap:4px;
	width: max-content;

}

.info {
	position: relative;
	top: 2px;
}

.boulders_library__table-body {
	display: flex;
	flex-wrap: wrap;
	flex-grow: 1;
	width: 100%;
    align-items: center;
    justify-content: flex-start;
}

.comment-box-container{
	display: flex;
	flex-direction: row;
	padding-bottom: 16px;
	gap:20px;
	justify-content: space-between;
	align-items: flex-start;
	border-bottom: solid 1px var(--color-grey-500);
	width: 100%;
}

.colorDivGrip {
    width: 30px;
    height: 30px;
    border-radius: 2px;
    opacity: 0.4;
}

.selectedGrip {
    padding: 4px;
    border-width: 1px;
    border-color: var(--colorPrimary400);
    background-color: var(--colorPrimary25);
    border-radius: 4px;
}

.no-color-selectedGrip {
    opacity: 0.4;
}

.is-for-detail-route-grip {
    opacity: 1;
}

.best-boulder-card-list-container{
	display: flex;
	flex-direction: row;
	width: 100%;
	gap:16px;
	flex-wrap: wrap;
}

.best-boulder-card-component-container{
	display: flex;
	flex-direction: row;
	gap:12px;
	padding : 20px;	
	border-radius: 10px;
	border :solid 1px var(--color-grey-300);
	width: 46%;
    justify-content: space-between;

}

.show-more-best-boulder-cards-button{
	display: flex;
	align-self: center;
	padding:8px 64px;
	color: var(--color-grey-100);
	border-radius: 10px;
	background-color: var(--color-back-office-700);
}

.best-boulder-card-component-visuals-container{
	display: flex;
	flex-direction: column;
	gap:8px;
	color: var(--color-back-office-800);
}

.best-boulder-card-component-visuals{
	display: flex;
	flex-direction: row;
	gap:8px;
}

.best-boulder-card-component-visuals img{
	border-radius: 2px;
	width: 104px;
	height: 130px;
}

.best-boulder-card-component-visuals-description{
	display: flex;
	flex-direction: column;
	padding-top: 8px;
	color: var(--color-grey-700);
	justify-content: space-between;
}

.vertical-best-boulder-component-line{
	height: 100%;
	border-right: solid 1px var(--color-grey-300);
}

.best-boulder-card-component-stats-container{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 4px;
	align-items: flex-end;
	
}

.best-boulder-card-component-essential-stat{
	display: flex;
	flex-direction: column;
	padding: 8px 16px;
	border-radius: 10px;
	border:solid 1px var(--color-back-office-500);
	background-color: var(--color-back-office-25);
	color: var(--color-back-office-800);
}

.best-boulder-card-component-seconday-stat-container{
	display: flex;
	flex-direction: column;
	gap:8px
}

.comment-box-container-small-card{
	display: flex;
	flex-direction: row;
	padding-bottom: 16px;
	gap:20px;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
}

.comment-box-content{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	height: 100%;
	width: 80%;
}

.comment-box-content-no-date{
	display: flex;
	flex-direction: row;
	gap:8px;
}

.comment-box-content-picture img{
	height: 48px;
	width: 48px;
	border-radius: 50px;
}

.comment-box-content-written{
	display: flex;
	flex-direction: column;
	gap:8px;
}

.comment-box-boulder-picture img{
	width: 80px;
	height: 100px;
	border-radius: 2px;
}

.comment-box-boulder-info{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.comment-box-boulder-info-written{
	display: flex;
	flex-direction: column;
	gap:8px;
	color: var(--color-grey-700);
}

.comment-box-boulder-info-visual{
	display: flex;
	flex-direction: column;
	gap:8px;
	
}

.comment-box-boulder{
	display: flex;
	flex-direction: row;
	gap:24px;
	width: 17%;
}

.loading-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.2); /* Adjust the color and opacity as needed */
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 3000; /* Make sure this is above everything else in the container */
}

.table-body__row__outer-container{
	display: flex;
	flex-direction: column;
	cursor: pointer;
	width: 100%;
	

}

.table-body__row {
	display: flex;
	padding: 4px 24px;
	position: relative;
	align-items: center;
	min-height: 34px;
	max-height: 34px;
	gap: 16px;
	border-bottom: 1px solid var(--color-grey-300);
	justify-content: space-between;
	
	
}

.row__gym {
	width: 112px;
	color: var(--color-grey-800);
}

.row__sector {
	min-width: 136px;
	width: 12%;
	color: var(--color-grey-800);
}

.row__sector2 {
	min-width: 136px;
	width: 12%;
	color: var(--color-grey-800);
}

.row__route-name {
	min-width: 226px;
	width: 20%;
	color: var(--color-grey-800);
}

.row__route-name2 {
	min-width: 226px;
	width: 20%;
	color: var(--color-grey-800);
}

.row__color {
	min-width: 72px;
	width: 6.4%;
	height: 24px;
	border-radius: 2px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	
}

.row__color2 {
	min-width: 72px;
	width: 6.4%;
	border-radius: 2px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	
}

.row__difficulty {
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	min-width: 72px;
	width: 6.4%;
	color: var(--color-grey-800);
}


.row__difficulty2 {
	min-width: 72px;
	width: 6.4%;
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	color: var(--color-grey-800);
}


.row__created-at {
	display: flex;
	justify-content: flex-start;
	min-width: 116px;
	width: 10.3%;
	color: var(--color-grey-800);
}

.row__created-at2 {
	display: flex;
	justify-content: flex-start;
	min-width: 116px;
	width: 10.3%;
	color: var(--color-grey-800);
}

.row__tags {
	min-width: 154px;
	width: 13.6%;
	color: var(--color-grey-800);
}

.row__tags2 {
	min-width: 154px;
	width: 13.6%;
	color: var(--color-grey-800);
}

.row__status__container{
	display: flex;
	flex-direction: row;
	gap: 16px;
	align-items: center;
	min-width: 106px;
	width: 9.4%;
}

.row__status__container2{
	display: flex;
	flex-direction: row;
	gap: 16px;
	align-items: center;
	min-width: 106px;
	width: 9.4%;

}

.row__status__open {
	display: flex;
	width: 68px;
	padding: 2px 4px;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	border-radius: 15px;
	color: var(--color-grey-100);
	background-color: var(--color-secondary-300);
}

.row__status__close {
	display: flex;
	width: 68px;
	padding: 2px 4px;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	border-radius: 15px;
	color: var(--color-grey-100);
	background-color: var(--color-orange-400);
}

.row__image {
	display : none;
	position: fixed; 
	transform: translate(-250%,-205%);
    z-index: 10000;
}

.extended-row-container{
	display: flex;
	flex-direction: column;
	gap:16px;
	padding: 16px;
	background-color: var(--color-back-office-25);
	color: var(--color-back-office-800);
	border-bottom: 1px solid var(--color-grey-300);
	cursor: default;
}

.extended-row-title{
	color: var(--color-back-office-800)
}

.extended-row-title-container-route-summary{
	display: flex;
	flex-direction: row;
	gap:24px
}

.extended-row-title-container-route-summary-second-part{
	display: flex;
	flex-direction: column;
	gap:32px
}

.extended-row-title-container-route-summary-second-part-stats-portion{
	display: flex;
	flex-direction: column;
}

.extended-row-title-container-route-summary-second-part-stats-portion-2{
	display: flex;
	flex-direction: column;
	gap:16px
}

.extended-row-title-container-route-summary-second-part-stats-portion-3{
	display: flex;
	flex-direction: column;
	gap:12px
}

.extended-row-title-container-route-summary-second-part-stats-portion-3-title{
	align-self: flex-end;
	display: flex;
	flex-direction: row;
	gap:4px

}

.extended-row-title-container-route-summary-second-part-stats-portion-3-title-1{
 color: var(--color-grey-700);
}

.extended-row-title-container-route-summary-second-part-stats-portion-3-title-2{
	color: var(--color-back-office-500);
}

.box-stats-container{
	display: flex;
	flex-direction: row;
	gap:16px
}

.extended-row-title-container-route-summary img {
    border-radius: 2px;
    min-height: 298px;
    min-width: 240px;
    width: 240px;
    height: 240px;
}

.extended-row-title-container-main-overview{
	display: flex;
	flex-direction: column;
	gap:16px;
	padding-top: 8px;
	padding-bottom: 24px;
}


.extended-row-title-container-main-overview-container{
	display: flex;
	flex-direction: row;
	gap:16px
}

.extended-row-description-tag-content{
	padding: 4px 12px 4px 12px;
	align-items: center;
	justify-content: center;
	display: flex;
	border :solid;
	border-width: 1px;
	border-color: var(--color-grey-700);
	color: var(--color-grey-900);
	border-radius: 20px;
	background-color: var(--color-grey-200);
}

.right-side-stats-container{
	display: flex;
	flex-direction: column;
	padding-top: 8px;
	gap:8px;
	align-items: flex-start;
	width: 100%;
}

.statistic-row {
    display: flex;
    align-items: center;
    width: 100%;
	height: 24px;
}

.difficulty-summary-item-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    width: 100%;
}

.difficulty-summary-item-container-name{
	max-width: 96px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	color: var(--color-back-office-800);
}

.difficulty-summary-item-container-visual{
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 16px 8px 16px;
	border-radius: 10px;
}
.difficulty-circuit-stats-item-container-visual{
	display: flex;
	align-items: center;
	padding: 12px;
	border-radius: 5px;
	gap: 20px;
	flex-direction: row;
	border: solid 1px var(--color-grey-300);
	justify-content: space-between;
}

.difficulty-circuit-item-container-visual{
	display: flex;
	align-items: center;
	
	padding: 8px 12px 12px 12px;
	border-radius: 3px;
	gap: 20px;
	flex-direction: row;
	border-width: solid 4px;
	justify-content: flex-start;
}

.difficulty-circuit-item-container-visual:hover {
    border-top: 3px solid ;
    border-right: 3px solid ;
	justify-content: space-between;
	
}


.inner-level-split-container{
	display: flex;
	flex-direction: row;

}

.detail-circuit-button {
    display: none;
	align-items: center;

}

.detail-circuit-stat-button {
    display: flex;
	flex-direction: row;
	gap : 8px;
	color: var(--color-grey-600);
	align-items: center;
}

.detail-circuit-stat-hovered-button {
    display: flex;
	flex-direction: row;
	gap : 8px;
	color: var(--color-back-office-500);
	align-items: center;
}

.difficulty-circuit-item-container-visual:hover .detail-circuit-button {
    display: flex;
	flex-direction: row;
	gap : 8px;
	color: var(--color-grey-600);
}

.common-inner-level-container-style{
	display: flex;
	flex-direction: column;
	gap:4px;
	align-items: center;
	
}

.difficulty-summary-item-container-visual-color-container{
	position: absolute;
	display: flex;
	flex-direction: row;
	top: 0;
	left: 0;
	width: 100%;
}

.common-inner-level-style{
 padding: 4px 0px;
 border : solid 1px;
 display: flex;
 width: 100%;
 align-items: center;
 justify-content: center;
}


.difficulty-summary-item-container-visual-color{
	width: 50%;
}

.statistic-row-container{
	display: flex;
	flex-direction: column;
	gap:4px;
	width: 100%;
}

.statistic-label {
    white-space: nowrap;
    padding-right: 8px;
	color: var(--color-grey-600);
}

.statistic-line {
    flex: 1;
    border-bottom: 1px solid #ccc;
    margin : 0 8px 6px 0;
	align-self: flex-end;
}

.statistic-component {
    align-self: center;
}

.extended-row-description-tag-header{
	padding-left: 4px;
	color: var(--color-grey-900);
}

.extended-row-description-container{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	
}

.extended-row-description-tags-container{
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	gap:8px
}

.extended-row-description-single-tag-container{
	display: flex;
	flex-direction: column;
	gap: 4px;
	align-items: flex-start;
}

.extended-row-description-detailed-caracteristics{
	display: flex;
	flex-direction: row;
	gap: 8px;
}

.extended-row-description-detailed-caracteristics-box{
	padding:12px;
	gap:4px;
	display: flex;
	flex-direction: column;
	background-color: var(--color-back-office-600);
	border-radius: 10px;
	align-items: flex-start;
	justify-content: center;
	color: white;
}

.extended-row-description-detailed-caracteristics-value-difficulty{
	display: flex;
}

.custom-extended-route-row-description-difficulty{
	height: 20px;
	width: 20px;
	border-radius: 2px;
	overflow: hidden;
}

.extended-row-description-climber-stats{
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding-bottom: 8px;
}

.extended-row-description-climber-stats-box{
	padding:12px;
	gap:4px;
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	align-items: flex-start;
	justify-content: center;
	color: white;
}

.flat-stat-box{
	background-color: var(--color-tertiary-500);
}

.success-stat-box{
	background-color: var(--color-secondary-500);
}

.global-stat-box{
	background-color: var(--color-purple-500);
}

.ranking-stat-box{
	background-color: var(--color-grey-50);
	border:solid;
	border-width: 1px;
	border-color: var(--color-grey-400);
}

.picture-hovering{
	position: relative;
	
}

.picture-hovering:hover .row__image {
	display: block;
	flex-direction: column;
	position: fixed;
	transition: 0.5s;
	 
	padding: 4px 8px 4px 8px;
	gap: 2px;
	border-radius: 10px;
	background-color: var(--color-grey-200);
	color: var(--color-peter_river-500);
	
}

.picture-hovering:hover .row__image img {
	width: 208px;
	height: 312px;
	border-radius: 2px;
	
	
}

.boulders-library__table-footer {
	display: flex;
	padding: 16px 0px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
	align-self: stretch;	
}

.more-detail-button-242px__container {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.more-detail-button-242px {
display: flex;
padding: 8px 64px;
justify-content: space-between;
align-items: center;
align-self: center;
margin-top : 16px;
margin-bottom: 16px;
gap: 8px;	
border-radius: 10px;
background: var(--color-back-office-700);
color: var(--color-grey-100);
}

.no-routes-msg {
	display: flex;
	align-self: center;
	color : var(--color-grey-400);
	padding: 24px;

}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
color: var(--color-grey-700);
font-family: 'Nunito Sans', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;

}

input[type=number]{
width: 32px;
border: none;
height: 30px;
text-align: center;
font-size: larger;
background-color: var(--color-grey-300);
}

/* Firefox */
input[type=number] {
appearance: textfield;
text-align: center;
font-size: larger;
color: var(--color-grey-700);
font-family: 'Nunito Sans', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}

.grade{
display: flex;
flex-direction: row;
gap: 31px;
align-items: center;
color: var(--color-grey-700);
font-family: 'Nunito Sans', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;

}

.grade_input{
display: flex;
flex-direction: row;
gap: 5px;
background-color: var(--color-grey-300);
padding-left: 8px;
padding-top: 8px;
padding-bottom: 8px;
padding-right: 8px;
border-radius: 25px;
margin-top: 2px;

}

.grade_input input{
border: none;
background-color: var(--color-grey-300);
width: 32px;
font-family: 'Nunito Sans', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
color: var(--color-grey-700)

}



.btn_decrement_grade{
width: 24px;
height: 24px;
background-size: cover;
background-color: var(--color-grey-300);
cursor: pointer;
border: none
}

.btn_increment_grade{
width: 24px;
height: 24px;
background-size: cover;
background-color: var(--color-grey-300);
cursor: pointer;
border: none
}


.hidden {
display: none !important;
}

.color {
display: flex;
align-items: center; /* This is to vertically align the text and the boxes if they have different heights */

}

.difficulty {
margin-right: 8px; /* This is to give some space between the text and the color boxes */
}

.color_box {
width: 24px;
height: 24px;
margin: 4px; /* This will give an 8px space between boxes because each box has a 4px margin on each side, which adds up to an 8px space. */
display: inline-block;
border:solid;
border-color: var(--color-blackorwhite-black);
border-width: 1px; /* This makes the color boxes to appear side by side */
}

.color_boxes { /* Assuming this is the container for your color boxes */
display: flex;
flex-wrap: wrap; /* This allows the boxes to wrap to the next line if they exceed the container's width */
justify-content: start; /* Aligns the boxes to the start of the container */
align-items: start; /* Aligns the boxes to the top of the container */
}





#sector_name{
font-family: 'Nunito Sans', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
color: var(--color-grey-700);
text-align: center;

}





.route-field-class{
display: flex;
width: 200px;
text-align: left;
padding-top: 8px;
align-items: flex-start;
border-radius: 5px;
border: 1px solid var(--color-grey-300);
background: #FFF;
color: var(--color-grey-600);
font-family: 'Nunito Sans', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 20px;
grid-column: 2 / 3;
appearance: none;
-webkit-appearance: none;
-moz-appearance: none;

background-repeat: no-repeat;
background-position: right center;
background-position-x: 95%;
padding-right: 20px; /* Adjust the padding to leave space for the SVG */
padding-left: 8px;


}

.selected {
border: 3px solid black;
outline: 1px solid white;
outline-offset: -5px;
}


.session_create_route{
	display: flex;
	width: 424px;
	flex-direction: column;
	align-items: center;
	background-color: var(--color-grey-100);
	min-width: 390px;
	border-radius: 10px;
	border: 1px solid var(--color-grey-300);
	background: var(--color-grey-50);
	box-shadow: 5px 5px 10px 0px rgba(19, 37, 26, 0.10);
	margin-bottom: 24px;
	align-self: center;


}

.route_form_title{
color: var(--color-peter_river-700);
font-family: 'Nunito Sans', sans-serif;
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: 32px; /* 114.286% */
padding-top: 16px;
}

.form_route_creation{
display: flex;
flex-direction: column;
}

.problem_name_container{
display : flex;
flex-direction: row;
align-self: stretch;
gap:16px;
width: 328px;
margin-top: 24px;

}

.name_lable{
color: var(--color-grey-800);
text-align: center;
font-family: 'Nunito Sans', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
padding-top: 4px;
}

#id_name{
display: flex;
width: 200px;
padding: 4px 8px;
align-items: flex-start;
gap: 8px;
border-radius: 5px;
border: 1px solid var(--grey-300, #D0D5DD);
background: #FFF;
font-family: 'Nunito Sans', sans-serif;
color: var(--grey-700, #344054);
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 20px; /* 125% */
}

.container_sector{
margin-top: 16px;
}

.color_label{
color: var(--color-grey-800);
font-family: 'Nunito Sans', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 32px;
padding-right: 24px;
}

.colors_container{
display: flex;
flex-direction: row;
align-self:stretch;
margin-top: 16px;
}

.cotation_container{
display: flex;
flex-direction: row;
align-self:stretch;
margin-top: 16px;
}

.cotation_label{
color: var(--color-grey-800);
font-family: 'Nunito Sans', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 42px;
padding-right: 24px; 
}

.tag_label{
color: var(--color-grey-800);
font-family: 'Nunito Sans', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 36px;
padding-right: 24px; 
}

.tag_container{
display: flex;
flex-direction: column;
align-self:stretch;
margin-top: 16px;

gap:16px
}

.tag_label_container{
display: flex;
flex-direction: row;
justify-content: space-between;

}

.btn_increment_tag{
width: 24px;
height: 24px;
background-size: cover;
background-color: var(--color-grey-100);
cursor: pointer;
border: none;
margin-top:4px
}

.loads_grid{
display: grid;
grid-template-columns: 140px 28px 30px;
column-gap: 16px;
grid-template-rows: 40px 40px 30px;
row-gap: 16px;
margin-top: 24px;
}

.start_flash_label{
color: var(--color-grey-800);
font-family: 'Nunito Sans', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 36px;
padding-right: 24px; 
grid-column: 1/2;
grid-row: 1/2;

}


.button_qr_start{
width: 24px;
height: 24px;
background-size: cover;
background-color: var(--color-grey-100);
cursor: pointer;
border: none;
margin-top:4px;
grid-column: 2/3;
grid-row: 1/2;

}

.top_flash_label{
color: var(--color-grey-800);
font-family: 'Nunito Sans', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 36px;
padding-right: 24px; 
grid-column: 1/2;
grid-row: 2/3;
}

.button_qr_top{
width: 24px;
height: 24px;

background-size: cover;
background-color: var(--color-grey-100);
cursor: pointer;
border: none;
margin-top:4px;
grid-column: 2/3;
grid-row: 2/3;

}

.route_picture_label{
color: var(--color-grey-800);
font-family: 'Nunito Sans', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 36px;
padding-right: 24px; 
grid-column: 1/2;
grid-row: 3/4;
}

.button_photo{
width: 24px;
height: 24px;
color: var(--color-grey-800);
grid-column: 2/3;
grid-row: 3/4;

background-size: cover;
background-color: var(--color-grey-100);
cursor: pointer;
border: none;
	
}

.upload_route_form_label{
color: var(--color-grey-50);
text-align: center;
font-family: 'Nunito Sans', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 20px; 
}

.upload_route_form_svg{

background-size: cover;
background-color: var(--color-grey-100);
cursor: pointer;
border: none;
}

.upload_route_form{
display: flex;
padding: 12px 24px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 15px;
background: var(--color-peter_river-800); 
align-self: stretch;
margin-top: 16px;
margin-bottom: 16px;

}

.form-grade-displayed{
justify-self: center;
}

#tickmark1{
width: 16px;
height: 14px;
color: var(--color-grey-800);
grid-column: 3/4;
grid-row: 1/2;

background-size: contain;
background-color: var(--color-grey-100);
border: none;
display: none;
margin-top: 6px;
}

#tickmark2{
width: 16px;
height: 14px;
grid-column: 3/4;
grid-row: 2/3;

background-size: contain;
background-color: var(--color-grey-100);
border-style: none;
display: none;
margin-top: 6px;

}

.btn-primary a strong{
	color: #FFF;
}

.privacy-links{
	display: flex;
	align-self: flex-start;
	flex-direction: column;
	gap: 36px;
	padding-left: 48px;
	
}

.buttons-privacy-links{
	display: flex;
	flex-direction: 'row';
	gap:24px
}

.general_conditions_outercontainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	gap:18px;
	padding-left: 24px;
	padding-right: 24px;
}

/* PASSWORD RESET */
.password_reset_outercontainer {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	
}

.password_reset_container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: var(--color-grey-100);
	gap:32px;
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 40px;
	padding-bottom: 40px;
	border: solid;
	border-width: 1px;
	border-radius: 5px;
	border-color: var(--color-grey-500);
	box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.25);
	width: 480px;
	
}

.gap-16{
	padding-right: 16px;
	padding-top:2px
}

.password_reset_title_description {
 display: flex;
 align-items: flex-start;
 gap: 16px;
 flex-direction: column;
}

.password_reset_title {
	font-size: 40px;
	line-height: 40px;
	color: var(--color-grey-900);
	font-family: 'Nunito Sans', sans-serif;
	font-weight: 400;
}

.password_reset_description {
	font-size: 20px;
	line-height: 24px;
	color: var(--color-grey-900);
	font-family: 'Nunito Sans', sans-serif;
	font-weight: 300;
}

.password_reset_container_form {
	display: flex;
	align-items: stretch;
	justify-content: center;
	flex-direction: column;
	gap : 16px;
	width: 100%;
}

.password_reset_container_form_input_icon_container{
	display: flex;
	flex-direction: row;
	border-radius: 10px;
	border : solid;
	border-width: 1px;
	border-color: var(--color-grey-600);
	justify-content: space-between;
	padding: 16px;
	background-color: white;
	align-items: center;
	align-self: stretch;

}

.password_reset_container_form_input_icon_container_error{
	display: flex;
	flex-direction: row;
	border-radius: 10px;
	border : solid;
	border-width: 1px;
	border-color: var(--color-orange-600);
	justify-content: space-between;
	padding: 16px;
	background-color: white;
	align-items: center;
	align-self: stretch;

}

.password_reset_container_form_full_formik_validation{
	display: flex;
	flex-direction: column;
	gap:8px;
}

.difficulty-summary-container{
	display: flex;
	flex-direction: column;
	gap:16px
}


.password_reset_container_form input {
    border-width: 0;
	border:none;
	line-height: 24px;
	width: 100%;
	font-size: 20px;
}
.password_reset_container_form input:focus {
    color:var(--color-grey-900); /* Placeholder text color */
    font-size: 20px;
	line-height: 24px;
	font-weight: 300;
	border: none;
	border-width: 0;
	outline: none;
}

.password_reset_container_form input::placeholder {
    color: var(--color-grey-600); /* Placeholder text color */
    font-size: 20px;
	line-height: 24px;
	font-weight: 300;
}

.password_reset_container_form input.error_input::placeholder {
    color: var(--color-error-600); /* Placeholder text color */
    font-size: 20px;
	line-height: 24px;
	font-weight: 300;
}

.eye_layout{
	background-color: white;
}

.error_password_message{
	margin-left: 16px;
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
	color: var(--color-error-600);
}

.password_indication{
	margin-left: 4px;
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
	color: var(--color-grey-700);
}


.confirm_password_change{
	margin-top: 16px;
	padding-left: 24px;
	padding-top: 20px;
	padding-right: 24px;
	padding-bottom: 20px;
	background-color: var(--color-primary-700);
	border-radius: 10px;
	color: white;
	font-size: 24px;
	line-height: 28px;
	font-weight: 700;
	align-self: center;
	margin-left: 10%;
    margin-right: 10%;
	border: none;
	cursor: pointer;
}

.confirm_password_change_disabled{
	margin-top: 16px;
	padding-left: 24px;
	padding-top: 20px;
	padding-right: 24px;
	padding-bottom: 20px;
	background-color: var(--color-primary-400);
	border-radius: 10px;
	color: white;
	font-size: 24px;
	line-height: 28px;
	font-weight: 700;
	align-self: center;
	margin-left: 10%;
    margin-right: 10%;
	border: none;
	cursor: default;
}

.password_changed_confirmed_outercontainer{
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
}

.password_changed_confirmed_container{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: var(--color-grey-100);
	gap:40px;
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 60px;
	padding-bottom: 60px;
	border: solid;
	border-width: 1px;
	border-radius: 5px;
	border-color: var(--color-grey-500);
	box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.25);
	width: 480px;
}

.password_changed_confirmed_title{
	font-size: 40px;
	line-height: 40px;
	color: var(--color-grey-900);
	font-family: 'Nunito Sans', sans-serif;
	font-weight: 400;
	text-align: center;
}

.password_changed_confirmed_description{
	font-size: 20px;
	line-height: 24px;
	color: var(--color-grey-900);
	font-family: 'Nunito Sans', sans-serif;
	font-weight: 300;	
	text-align: center;
}

.logo-gym{
	gap:16px;
	display: flex;
	flex-direction: 'row';
}

@media screen and (max-width: 500px) {
	
	html,body{
		max-width: 100vw;
		background-color: var(--color-secondary-300);
		
	}

	#root{
		max-width: 100%;
		
	}

	.should-be-displayed{
		display: none;
	}

	.additional-property-mobile{
		margin-left: -4px;
	}

	.Landing-page-background {
		max-width: 100%;
		padding-left: 12px;
		padding-right: 12px;
	}
    .Landing-page-header {
        display: flex; /* Switch from grid to flex */
        flex-direction: column; /* Stack children vertically */
        align-items: center; /* Center-align the flex items */
        justify-content: center;
        min-width: auto; /* Remove the min-width restriction */
    }

    .Landing-page-header-empty-space,
    .Landing-page-route-setter-button,
	.app-demo-container {
        display: none; /* Hide these elements */
    }

	.Landing-page-presentation-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 0px;
		background-color: var(--color-primary-200);
		padding: 32px; /* Make sure to specify units for padding */
		position: relative; /* Needed for absolute positioning */
		border-radius: 20px;
		border-width: 2px;
		border:solid;
		border-color: black;
		box-shadow: 2px 4px 0px 0px #000;	
		max-width: 100vw;	
		min-width: auto;
		margin-left: 0px;
		margin-right: 0px;
	}

	.Landing-page-presentation-description-focus-text{
		font-size: 28px;
		line-height: 32px;
		padding-top: 4px;
		padding-bottom: 4px;
		
	}

	.Landing-page-presentation-description-standard-text{
		font-size: 24px;
		line-height: 28px;
		padding-left: 8px;
		padding-right: 8px;
	}

	.Landing-page-presentation-description-last-text-box{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap:4px
	}

	.Landing-page-presentation-description-standard-text-last{
		font-size: 24px;
		line-height: 32px;
		flex-direction: column;
		gap:0px
	}
	
	.Landing-page-gym-finder-container{
		min-width: 100%;
		max-width: 100%;
		margin-left: 0px;
		margin-right: 0px;
	}

	.Landing-page-gym-finder-header-condensed{
		display: flex;
		flex-direction: column;
		gap:4px
	}

	.Landing-page-gym-finder-header-description{
		font-size: 18px;
		line-height: 24px;
	}

	.Landing-page-gym-finder-header-subtitle{
		font-size: 16px;
		line-height: 24px;
	}	

	.Landing-page-gym-finder-formik-container{
		align-items: center;
		width: 100%;
	
	}

	.Landing-page-gym-finder-formik-input{
		flex-direction: column;
		align-items: center;
		width: 100%;
		gap:16px;
	}
	.autocomplete-container{
		width: 100%;
		display: flex;
		align-self: center;
		justify-content: center;
	}

	.Landing-page-gym-finder-formik-input-inner-container{
		width: 100%;
		align-items: center;
	}

	.input-select-gym{
		
		max-width: auto;
		min-width: 80%;
	}

	.Landing-page-gym-finder-button{
		align-self: center;
		align-items: center;
		margin-top: 24px;
	}

	.Landing-page-gym-finder-pro{
		flex-direction: column;
		align-items: center;
		align-self: center;
	
	}

	.Landing-page-gym-finder-header-professional-button{
		border-radius: 10px;
		border: 2px solid var(--color-primary-400);
		background: var(--color-back-office-25);
		padding: 16px 24px;
		align-items: center;
		justify-content: center;
		text-wrap: wrap;
		
	}

	.Landing-page-gym-finder-header-professional-button-text{
		font-weight: 700;
		text-align: center;
		
	}

	.heading{
		font-size: 14px;
		line-height: 18px;
	}
	
	.heading1{
		font-size: 16px;
		line-height: 20px;
	}

	.app-demo-container-mobile{
		margin-top: -76px;
		display: flex;	
		width: 100%; /* Width as specified */
		margin-bottom: 12px;
		
		
	}
	.app-demo-container-mobile img{
		display: flex;	
		width: 100%; /* Width as specified */
		
		
	}
	.landing-page-footer{
		display: flex;
		flex-direction: column;
		align-items: center;
		min-width: 100vw;
		
	}

	.landing-page-footer-left-side{
		display: none;
	}
	.landing-page-footer-right-side{
		height: auto;
		flex-grow: 1;
		gap:32px
	}
	.landing-page-footer-right-side-socials-container{
		flex-wrap: wrap;
		gap:32px;
		padding-left: 0px;
	}
	.logo-gaston{
		display: none;
	}
	.logo-gym{
		gap:16px;
		display: flex;
		flex-direction: 'row';
	}

	.title_party_header{
		font-size: 16px;
		line-height: 20px;
	}

	.party_subHeader_component_2{
		display: none;
	}

	.party_subHeader_component{
		width: 100%;
		padding : 0 16px
	}
	
	.party_outercontainer {
		display: flex;
		align-items: center;
		padding-top: 16px;
		justify-content: flex-start;
		height: 100%;
		width: 100%;
		background-color:var(--color-secondary-200) ;
		flex-direction: column;
		
	}

	.party_outercontainer_bis {
		display: flex;
		align-items: center;
		padding-top: 16px;
		justify-content: flex-start;
		height: 100%;
		width: 100%;
		background-color:var(--color-secondary-400) ;
		flex-direction: column;
		flex: 1;
		
	}

	.party_ranking_section{
		padding: 32px 16px;
	}

	.party_ranking_tabs_section_container{
		padding: 12px;
		max-width: 100%;
	}

	.ranking_section{
		width: 100%;
	}

	.large2{
		font-size: 14px;
		line-height: 18px;
	}

	.party_header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding-top: 20px;
		padding-bottom: 20px;
		padding-left: 16px;
		padding-right: 16px;
	}

}